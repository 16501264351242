import React from 'react'
import { Link } from 'react-router-dom'

export const Error = () => {
  return (
    <div>
      Oops!, The page you are looking is not found
      <Link to='/'>back to Home</Link>
    </div>
  )
}
