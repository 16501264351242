import React from 'react'
import { Helmet } from 'react-helmet'
import immigrant from '../assets/images/canada-immigration-consultants.jpg'

export const AboutUs = () => {
  return (
    <main>
      <Helmet>
        <title>Leading Immigration Consultancy Firm in Riyadh</title>
        <meta
          name='description'
          content='We are ICCRC registered consultants in Saudi Arabia can help with student and work visas, as well as with Canada immigration for professionals and Australia immigration for professionals.'
        />
        <meta
          property='og:title'
          content='Leading Visa and Immigration Consultants in Saudi Arabia'
        />

        <meta
          property='og:description'
          content='We are ICCRC registered consultants in Saudi Arabia can help with student and work visas, as well as with Canada immigration for professionals and Australia immigration for professionals.'
        />
        <link rel='canonical' href='https://wayfairconsultants.com/about-us' />
      </Helmet>
      <section id='about' className='wow fadeInUp'>
        <div className='row'>
          <div className='col-md-12'>
            <h1>Leading Visa and Immigration Consultants in Saudi Arabia</h1>
          </div>
        </div>
        <div className='row mb-5'>
          <div className='col-lg-6 content'>
            <h2>
              Our <span className='text-primary'>Vision</span> is Your
              <span className='text-success'> Success</span>
            </h2>
            <p>
              Our Immigration Services include a comprehensive consultation to
              help identify and find the best Immigration Program to have a
              successful application.
            </p>
            <p>
              We are authorized representative working with Independent ICCRC
              (Immigration Consultant for Canadian Regulatory Council)
              consultant for Canada and MARA (Member of Australian Registration
              Authority) respectively.
            </p>
            <h2>ICCRC registered consultants in Saudi Arabia</h2>
            <p>
              We are ICCRC registered consultants in Saudi Arabia can help with
              student and work visas, as well as with Canada immigration for
              professionals. We are knowledgeable about the process and
              requirements for each type of visa, and can help make the process
              as smooth and stress-free as possible. If you are considering
              immigrating to Canada or Australia, we can help you with all the
              necessary paperwork and requirements.
            </p>
            <p>
              More than a decade of experience in assisting expatriates
              communities from middle east & india, wayfair consultants has an
              ethical way of approach working hand in hand with clients looking
              for resettlement in countries like canada / australia / uk / usa
              etc. as permanent residents. Be it an skilled professional working
              in his profession or a business men or a student looking to pursue
              his higher education in above mentioned countries, we provide
              bespoke support and offer advice which is effective and
              affordable, and works in the best interest of each individual
              client
            </p>
            <p>
              Wayfair consultants is a Expert Immigration Consultants in Riyadh,
              Saudi Arabia offering best immigration services for Canada,
              Australia and USA
            </p>
          </div>
          <div className='col-lg-6 about-img mt-5'>
            <img
              src={immigrant}
              className='img-fluid'
              alt='immigration consultants in riyadh'
            />
          </div>
        </div>
      </section>
    </main>
  )
}
