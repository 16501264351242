import React from 'react'
import { FaArrowRight } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'

const Footer = () => {
  return (
    <footer className='footer-container'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='footer-content'>
              <div className='footer-col'>
                <h3>
                  Subscribe to get the latest <br />
                  news and updates from Wayfair consultants
                </h3>
                <form action='' method='post' className='form newsletter-form'>
                  <label
                    htmlFor='wayfaiconsultants-newsletter'
                    className='newsletter-label'
                  >
                    Join our newsletter
                  </label>
                  <input
                    type='text'
                    className='newsletter-form-input'
                    name='EMAIL'
                    id='wayfaiconsultants-newsletter'
                    placeholder='Email address here'
                  />
                  <button
                    className='submit-btn'
                    type='submit'
                    name='submit'
                    aria-label='submit'
                  >
                    <FaArrowRight />
                  </button>
                </form>
              </div>
              <div className='footer-col'>
                <h3>Links</h3>
                <nav className='footer-navigation'>
                  <NavLink to='/'>Home</NavLink>
                  <NavLink to='/about-us'>About us</NavLink>
                  <NavLink to='/student-visa'>student visa</NavLink>
                  <NavLink to='/canada'>canada</NavLink>
                  <NavLink to='/contact'>contact</NavLink>
                  <NavLink to='/australia'>australia</NavLink>
                </nav>
              </div>
              <div className='footer-col'>
                <h3>Contact Us</h3>
                <h4>Main Office</h4>
                <address>
                  <span>Office number 312, 3rd floor,</span>
                  <span> Gate # 4, Akaria-1,</span>
                  <br />
                  <span>Musa Bin Naseer Street, Olaya,</span>
                  <span> Riyadh</span>
                </address>
                <ul className='footer-list'>
                  <li>
                    <a href='tel:00966 546775372'>00966 546775372</a>
                  </li>
                  <li>
                    <a href='tel:00966 558877372'>00966 558877372</a>
                  </li>
                  <li>
                    <a href='mailto:info@wayfairconsultants.com'>
                      info@wayfairconsultants.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className='footer-content'>
              <small>
                &copy; Wayfair consultants
                <time dateTime={new Date().getFullYear()}>
                  {new Date().getFullYear()}
                </time>
                . All rights reserved
              </small>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
