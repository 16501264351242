import React from 'react'
import {
  FaEnvelopeOpen,
  // FaFacebook,
  // FaFacebookF,
  // FaLinkedinIn,
  FaPhone,
} from 'react-icons/fa'
import { NavLink, Link } from 'react-router-dom'
import logo from '../assets/images/logo.png'

export const Navbar = () => {
  return (
    <header>
      <nav className='navbar navbar-expand-lg navbar-light primary-color'>
        <div className='container'>
          {/* <a className='navbar-brand' href='/'> */}
          <Link to='/' className='navbar-brand'>
            <figure>
              <img
                src={logo}
                alt='wayfair consultants'
                width='80'
                height='80'
              />
              <span>
                Wayfair
                <span>consultants</span>
              </span>
            </figure>
            {/* </a> */}
          </Link>
          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarSupportedContent'
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon'></span>
          </button>
          <div className='collapse navbar-collapse' id='navbarSupportedContent'>
            <ul className='navbar-nav me-auto mb-2 mb-lg-0'>
              <li className='nav-item'>
                <NavLink to='/' className='nav-link' aria-current='page'>
                  Home
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink to='/about-us' className='nav-link'>
                  About us
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink to='/student-visa' className='nav-link'>
                  student visa
                </NavLink>
              </li>

              <li className='nav-item'>
                <NavLink to='/canada' className='nav-link'>
                  Canada
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink to='/australia' className='nav-link'>
                  australia
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink to='/contact' className='nav-link'>
                  contact
                </NavLink>
              </li>
            </ul>
          </div>

          <div className='contact-info'>
            <div>
              <span>
                <FaEnvelopeOpen />
                <a href='mailto:info@wayfairconsultants.com'>
                  info@wayfairconsultants.com
                </a>
              </span>
              <span>
                <FaPhone />
                <a href='https://wa.me/+966546775372'>00966 546775372</a>
              </span>
              <span>
                <FaPhone />
                <a href='https://wa.me/+966558877372'>00966 558877372</a>
              </span>
            </div>
          </div>
        </div>
      </nav>
    </header>
  )
}
