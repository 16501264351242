import React from 'react'
import { Helmet } from 'react-helmet'
export const Contact = () => {
  return (
    <main>
      <Helmet>
        <title>Riyadh's Top Immigration Consultancy</title>
        <meta
          name='description'
          content='We are ICCRC registered consultants in Saudi Arabia can help with student and work visas, as well as with Canada immigration for professionals. Contact us for more details '
        />
        <meta
          property='og:title'
          content='Immigration consultants in Riyadh, Saudi Arabia'
        />

        <meta
          property='og:description'
          content='We are ICCRC registered consultants in Saudi Arabia can help with student and work visas, as well as with Canada immigration for professionals. Contact us for more details'
        />
        <link rel='canonical' href='https://wayfairconsultants.com/contact' />
      </Helmet>
      <section>
        <div className='section-header'>
          <h1>Contact us</h1>
        </div>

        <div className='row'>
          <div className='col-lg-3'>
            <div className='contact-address'>
              <h2>Main Office</h2>
              <address>
                <span>Office number 312, 3rd floor,</span>
                <span> Gate # 4, Akaria-1,</span>
                <span>Musa Bin Naseer Street, Olaya,</span>
                <span> Riyadh</span>
              </address>
            </div>
            <div className='contact-phone'>
              <h2>Phone Number</h2>
              <p>
                <span>
                  <a href='tel:00966 546775372'>00966 546775372</a>
                </span>
                <span>
                  <a href='tel:00966 558877372'>00966 558877372</a>
                </span>
              </p>
            </div>
            <div className='contact-email'>
              <h2>Email</h2>
              <p>
                <a href='mailto:info@wayfairconsultants.com'>
                  info@wayfairconsultants.com
                </a>
              </p>
            </div>
          </div>
          <div className='col-lg-3'>
            <div className='contact-address'>
              <h2>Australia Office</h2>
              <address>
                <span>MARN #1792807, 2/35 Lewis Street,</span>{' '}
                <span>springvale 3171,</span>
                <span> Melbourne,</span> <span>Victoria</span>
              </address>
            </div>
            <div className='contact-phone'>
              <h2>Phone Number</h2>
              <p>
                <a href='tel:+61416773101'>+61416773101</a>
              </p>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='form'></div>
          </div>
        </div>
      </section>
    </main>
  )
}
