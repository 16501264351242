import React from 'react'
import { Helmet } from 'react-helmet'
import canadaSkilledVisa from '../assets/images/canada-skilled-visa.jpg'
export const Canada = () => {
  return (
    <main id='main'>
      <Helmet>
        <title>Reputable Immigration Experts in Riyadh</title>
        <meta
          name='description'
          content='If you are a skilled worker from overseas who wants to migrate to Canada, this program is a pathway to attain a permanent residency in Canada for skilled workers living outside Canada or who are living in Canada on a temporary visa status.'
        />
        <meta
          property='og:title'
          content='Immigration to Canada from Saudi Arabia'
        />

        <meta
          property='og:description'
          content='If you are a skilled worker from overseas who wants to migrate to Canada, this program is a pathway to attain a permanent residency in Canada for skilled workers living outside Canada or who are living in Canada on a temporary visa status.'
        />
        <link rel='canonical' href='https://wayfairconsultants.com/canada' />
      </Helmet>
      <section id='services'>
        <div className='row'>
          <div className='col-lg-12'>
            <h1 className='heading'>Immigration to Canada from Saudi Arabia</h1>
            <div className='row'>
              <div className='col-md-6'>
                <h2>Canada immigration services in Saudi Arabia</h2>
                <p>
                  Welcome to Wayfair Immigration Consultants in Riyadh, Saudi
                  Arabia! We are here to help you make your dream of becoming a
                  Canadian citizen a reality. We specialize in providing the
                  best Canada Immigration Consultancy services in Saudi Arabia.
                  Our team of experienced and professional Canada immigration
                  consultants have the knowledge and expertise to help you
                  navigate the complex immigration system and guide you through
                  the entire process.{' '}
                </p>
                <p>
                  At Wayfair Immigration Consultants, we understand how daunting
                  the process of immigrating to Canada can be. We know that you
                  have many questions and concerns and that’s why we are here to
                  provide you with the best Canada immigration services in
                  Riyadh. Our experienced consultants can advise you on the most
                  suitable immigration program that meets your unique needs, as
                  well as answer any questions you may have.
                </p>
                <p>
                  Our Canada immigration consultants in Riyadh focus on
                  providing the most comprehensive and up-to-date information on
                  Canadian immigration policies and procedures. We ensure that
                  you have the most accurate information and advice to make the
                  best decision for your future. Our team of professionals have
                  extensive experience dealing with all types of immigration
                  matters and can provide you with personalized solutions to
                  meet your individual needs.
                </p>
              </div>
              <div className='col-md-6 mb-4'>
                <img src={canadaSkilledVisa} alt='canadian skilled visa' />
              </div>
            </div>
            <p>
              In addition to providing the best Canada immigration services in
              Riyadh, Wayfair Immigration Consultants also offers additional
              services for those looking to move to Canada. We can help you with
              obtaining permanent residence, applying for a work permit, or even
              finding a job in Canada. We also provide assistance with programs
              such as the Provincial Nominee Program and the Start-up Visa
              Program.
            </p>
            <p>
              At Wayfair Immigration Consultants, we strive to be the best
              Canada immigration consultants in Riyadh. Our team of experienced
              and knowledgeable consultants will provide you with the best
              advice and assistance so that you can move to Canada and enjoy all
              that it has to offer. Contact us today and let us help you make
              your Canadian dream come true.
            </p>
            <p>
              If you are looking for the best Canada immigration consultants in
              Riyadh, look no further than Wayfair Immigration Consultants. We
              offer personalized solutions to meet all of your immigration
              needs, and our experienced consultants can provide you with the
              most up-to-date information and advice. Contact us today and let
              us help you realize your Canadian dream.
            </p>
            <h2>Express Entry</h2>
            <p>
              If you are a skilled worker from overseas who wants to migrate to
              Canada, this program is a pathway to attain a permanent residency
              in Canada for skilled workers living outside Canada or who are
              living in Canada on a temporary visa status. This is a pool based
              system and approximately every two weeks, the Canadian government
              conducts an Express Entry draw. Each draw has a cut-off score,
              which represents the score of the lowest-ranking candidate to
              receive an Invitation to Apply (ITA) for permanent residence. Once
              a candidate receives an ITA, they are given 60 days to complete
              and submit an official electronic Application for Permanent
              Residence (eAPR).In order to rank immigration candidates, the
              Canadian government developed a merit-based points system which
              assigns a score to each candidate in the Express Entry pool. This
              points system is called the Comprehensive Ranking System (CRS),
              and the score assigned to each candidate is called the CRS score.
            </p>
            <h2>Express Entry manages three programs:</h2>
            <ul>
              <li>Federal Skilled Worker (FSW)</li>
              <li>Federal Skilled Trades (FST)</li>
              <li>Canadian Experience Class (CEC)</li>
            </ul>
            <h2>PNP (Provincial Nominee Programs)</h2>
            <p>
              The Provincial Nominee Programs are worth considering if you are
              looking forward to settle permanently in any specific province.
              Under the Provincial Nominee Program each of the province or
              territories can nominate immigrants who have skills, education and
              work experience to contribute to the province or territory, they
              plan to live in. Each of the provinces has its own streams or
              immigration programs and criteria for their provincial Nominee
              programs.
            </p>
            <h2>
              The Canadian Provincial Nominee Programs are available in the
              following provinces:
            </h2>
            <ul>
              {' '}
              <li>Alberta</li>
              <li>Manitoba</li>
              <li>Newfoundland and Labrador</li>
              <li>Prince Edward Island</li>
              <li>Yukon</li>
              <li>British Columbia</li>
              <li>New Brunswick</li>
              <li>Nova Scotia</li>
              <li>Saskatchewan</li>
              <li>Ontario</li>
            </ul>
            <h2>Quebec Skilled Program</h2>
            <p>
              Quebec Province also known as a French colony in Canada has its
              own unique program Quebec Skilled Worker Program (QSWP) offering
              Permanent residency to applicants. It has own selection criteria
              to choose eligible skilled workers having an intentions to settle
              in Quebec province. The selected applicant under QSWP category
              receives a selection certificate where upon applicants can apply
              for Permanent residency to IRCC.
            </p>

            <h2>Family Sponsorship</h2>
            <p>
              Under this program an applicant is considered as member of the
              Family Class on the basis of their relationship to a Canadian
              citizen or a permanent resident. Canadian citizens (including, in
              certain circumstances, those residing abroad with the intention to
              return to Canada to reside) and permanent residents restricted to
              those residing in Canada, only and minimum age eighteen years and
              over may sponsor the following members of the Family Class subject
              to meeting eligibility requirements for Canada Immigration:
            </p>
            <ul>
              <li>spouse</li>
              <li>common-law/same-sex partner</li>
              <li>conjugal partner</li>
              <li>dependent children ( less than 19 yrs) </li>
              <li>parents</li>
              <li>grandparents</li>
            </ul>
          </div>
        </div>

        <div className='row'>
          <div className='col-lg-12  content'>
            <h2>Postlanding services</h2>
            <p>
              Canada is a multi-cultural society filled with people from various
              backgrounds. They vary from colour, race, food, etiquettes, etc.
              As a new comer to Canada you may take some time to settle in to
              this society and understand the people from different walks of
              life and the culture at home, public or office. WAYFAIR
              CONSULTANTS has a separate wing to promote resettlement because it
              is an efficient way to integrate the newcomer in the Canadian
              Society. Currently, our resettlement solution offers services to
              immigrants in Toronto & GTA region. We offer services on an
              individual, student, family and group basis. We look to bridge the
              gap between the new immigrant and the mainstream population of the
              Canadian Society.
            </p>
            <ul>
              <li>
                Arrangement for accommodation on arrival, depending on the size
                and requirement of families, individuals or Students
              </li>

              <li>Financial planning advice</li>

              <li>Home search, buy or rent</li>

              <li>
                Completion of paper works in Canada for Health, taxes and
                Banking
              </li>

              <li>
                One to one counselling on Job Search, which includes preparation
                of resume for Canadian employers, covering letters, places to
                look for jobs, the right approach required for the job market
              </li>

              <li>
                We can guarantee you minimal wage job (irrespective of location
                and nature of work)
              </li>

              <li>
                Way to upgrade the skills, help to learn advanced Speaking
                English for Canadian job market
              </li>
            </ul>
            <h2>Citizenship services</h2>
            <p>
              If you already have a PR & living in or outside Canada, we process
              citizenship applications for an individual / families. Renewals of
              PR if you are living in Canada.
            </p>
            <h2>Benefits Of Canada Immigration</h2>
            <ul>
              <li>Free Healthcare Services</li>
              <li>Free Education System</li>
              <li>Powerful Civil Rights</li>
            </ul>
            <h2>Spanish speaking medical professionals, nurses</h2>
            <p>
              Currently, Canada is in need of spanish speaking medical
              professionals. The employer takes care of your insurance, dental ,
              drugs, etc. If you are a skilled medical professional who can
              speak spanish, its good time to migrate and settle in Canada.
              Nurses, who can take special clinical care, who can carry out
              health and hygienic care to the residents and provide healthcare
              and wellness services to maintain and restore patients health and
              help prevent illness, should be a good responder to all emergency
              situations.
            </p>
          </div>
        </div>
      </section>
    </main>
  )
}
