import React from 'react'
import { Helmet } from 'react-helmet'
import studentImg from '../assets/images/student-visa.jpg'

export const StudentVisa = () => {
  return (
    <main>
      <Helmet>
        <title>Highly Recommended Immigration Consultants in Riyadh</title>
        <meta
          name='description'
          content='We offer Student Visa Service, Australia Student Visa, Canada Student Visa, USA student visa, student visa service from Saudi Arabia, we are immigrations consultants in Riyadh'
        />
        <meta
          property='og:title'
          content='Student Visa and Immigration Consultants in Saudi Arabia'
        />

        <meta
          property='og:description'
          content='We offer Student Visa Service, Australia Student Visa, Canada Student Visa, USA student visa, student visa service from Saudi Arabia, we are immigrations consultants in Riyadh'
        />
        <link
          rel='canonical'
          href='https://wayfairconsultants.com/student-visa'
        />
      </Helmet>
      <section>
        <div className='row'>
          <div className='col-lg-12  content'>
            <div className='section-header'>
              <h1 className='page-title'>
                Student Visa and Immigration Consultants in Saudi Arabia
              </h1>
              <div className='row'>
                <div className='col-md-6'>
                  <h2>Study in Aus/US/UK/Canada</h2>
                  <p>
                    We provide quality and reliable solutions and end-to-end
                    services to students aspiring to study overseas and those
                    seeking to move, settle overseas either temporarily or
                    permanently.
                  </p>
                  <p>
                    With respect to the student visa, we assist you, right from
                    counselling on the university and courses available till the
                    student visa process. Being Riyadh's Top Immigration
                    Consultancy We are official representatives of world
                    className universities and colleges for study in Australia,
                    New Zealand, Canada, USA, UK, and Singapore.
                  </p>
                  <h2>Are you looking to study in Canada?</h2>
                  <p>
                    Express entry opened entry to all categories, its very easy
                    to became Canadian resident and citizen as Canada is
                    inviting more than 4,50,000 applicants for 2023. There are
                    plenty of job opportunities. Talented employees can have
                    huge egos and plenty of job opportunities.
                  </p>
                  <p>
                    Wayfair consultants having 16+ years of experience in
                    overseas education and immigration consulting. A certified
                    company with registered IRCC lawyer connect. Contact our
                    consultants to get more details and free assessment.
                  </p>
                </div>
                <div className='col-md-6'>
                  <img
                    src={studentImg}
                    className=' mt-4'
                    alt='student visa for canada, student visa for australia'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}
