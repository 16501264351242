import React, { useState } from 'react'
import avatar1 from '../assets/avatars/avatar-01.png'
import avatar2 from '../assets/avatars/avatar-02.png'
import avatar3 from '../assets/avatars/avatar-03.png'
import { FaChevronLeft, FaChevronRight, FaQuoteRight } from 'react-icons/fa'

const reviews = [
  {
    name: 'Manisha Sharma',
    image: avatar1,
    text:
      'You are amazing and given my family and i the best support. Excellent service and we couldn’t have ask for a better team.',
  },
  {
    name: 'Abdul samad',
    image: avatar2,
    text:
      'I would like to thank you people to help me on my FLR file and getting success on that, thank you very much team',
  },
  {
    name: 'Asad Shah',
    image: avatar3,
    text:
      'Your team was amazing in providing immigration consultancy services to my family! Thank you for such efficient quick service',
  },
]
const Reviews = () => {
  const [index, setIndex] = useState(0)
  const { name, image, text } = reviews[index]
  const prevPerson = () => {
    setIndex((currentIndex) => {
      const newIndex = currentIndex - 1
      if (newIndex < 0) {
        return reviews.length - 1
      }
      return newIndex
    })
  }
  const nextPerson = () => {
    setIndex((currentIndex) => {
      const newIndex = currentIndex + 1
      if (newIndex > reviews.length - 1) {
        return 0
      }
      return newIndex
    })
  }
  return (
    <article className='review'>
      <div className='img-container'>
        <img src={image} alt={name} className='person-img' />
        <span className='quote-icon'>
          <FaQuoteRight />
        </span>
      </div>
      <h4 className='author'>{name}</h4>
      <p className='info'>{text}</p>
      <div className='button-container'>
        <button className='prev-btn' onClick={prevPerson}>
          <FaChevronLeft />
        </button>
        <button className='next-btn' onClick={nextPerson}>
          <FaChevronRight />
        </button>
      </div>
    </article>
  )
}

export default Reviews
