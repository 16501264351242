import React from 'react'
import { Helmet } from 'react-helmet'
import immigrateAustralia from '../assets/images/Immigrate-to-Australia.webp'
import immigrateCanada from '../assets/images/Immigrate-to-Canada.webp'
import australiaPRVisa from '../assets/images/Australia-PR-Visa.webp'
import skilledMigration from '../assets/images/Skilled-Migration-Consultants.webp'
import futureInCanada from '../assets/images/future-in-canada.webp'
import sideImg from '../assets/images/australia-immigraion-service-saudi-arabia.jpeg'
import maara from '../assets/images/maara.png'
import iccrc from '../assets/images/iccrc.png'
import Reviews from '../components/Reviews'

export const Home = () => {
  return (
    <>
      <Helmet>
        <title>
          Best Immigration Consultants &amp; Visa Services in Riyadh, Saudi
          Arabia
        </title>
        <meta
          name='description'
          content='If you already have a PR and living in or outside Canada, we process citizenship applications for an individual / families.'
        />
        <meta
          property='og:title'
          content='Immigration Consultants in Saudi Arabia | Canada immigration from
          Riyadh'
        />

        <meta
          property='og:description'
          content='If you already have a PR and living in or outside Canada, we process citizenship applications for an individual / families.'
        />
        <link rel='canonical' href='https://wayfairconsultants.com/' />
      </Helmet>
      <div className='row'>
        <div className='col-md-12'>
          <div
            id='carouselExampleSlidesOnly'
            className='carousel slide'
            data-bs-ride='carousel'
          >
            <div className='carousel-inner'>
              <div className='carousel-item active'>
                <img
                  src={immigrateAustralia}
                  className='d-block w-100'
                  alt='Saudi Arabia Immigration Services, Immigration Services Saudi Arabia, Immigration consultants In Saudi Arabia, Best Immigration Consultants in Saudi Arabia, Best Immigration Consultants & Visa Services in Saudi Arabia'
                />
              </div>
              <div className='carousel-item'>
                <img
                  src={immigrateCanada}
                  className='d-block w-100'
                  alt='canada immigration services Riyadh, Australia canada immigration services Riyadh, UK canada immigration services Riyadh, Immigration services & Visa Services'
                />
              </div>
              <div className='carousel-item'>
                <img
                  src={australiaPRVisa}
                  className='d-block w-100'
                  alt='canada immigration consultants Riyadh, Australia canada immigration consultants Riyadh, UK canada immigration consultants Riyadh'
                />
              </div>

              <div className='carousel-item'>
                <img
                  src={skilledMigration}
                  className='d-block w-100'
                  alt='Immigration consultants in Riyadh, Immigration consultants in Saudi, Immigration company in Riyadh, Migrate to Canada From Saudi Arabia, Migrate to UK From Saudi Arabia, Migrate to Australia From Saudi Arabia'
                />
              </div>
              <div className='carousel-item'>
                <img
                  src={futureInCanada}
                  className='d-block w-100'
                  alt='Way fair Immigration consultants, Immigration consultants & Visa Services'
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='services'>
        <div className='row'>
          <div className='col'>
            <h1 className='heading'>
              Canada and Australia Immigration Visa Services
            </h1>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-8 col-md-8 col-sm-12'>
            <div className='row'>
              <div className='col-md-6 col-lg-6 service'>
                <div className='card'>
                  <div className='card-body'>
                    <h2 className='card-title'>Immigration Services</h2>
                    <p className='card-text'>
                      We provide services to obtain permanent residency (PR) for
                      Canada and Australia
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-md-6 col-lg-6 service'>
                <div className='card'>
                  <div className='card-body'>
                    <h2 className='card-title'>Student Study Visa</h2>
                    <p className='card-text'>
                      We provide quality and reliable solutions and end-to-end
                      services to students aspiring to study overseas and those
                      seeking to move, settle overseas either temporarily or
                      permanently.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='row mt-4 mb-4'>
              <div className='col-md-6 col-lg-6 service'>
                <div className='card'>
                  <div className='card-body'>
                    <h2 className='card-title'>Post Landing Services</h2>
                    <p className='card-text'>
                      Canada is a multi-cultural society filled with people from
                      various backgrounds. They vary from colour, race
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-md-6 col-lg-6 service'>
                <div className='card'>
                  <div className='card-body'>
                    <h2 className='card-title'>Citizenship Services</h2>
                    <p className='card-text'>
                      If you already have a PR &amp; living in or outside
                      Canada, we process citizenship applications for an
                      individual / families.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-4 col-md-4 gx-5 canada-assessment-form '>
            <div className='row align-items-center h-100'>
              <div className='col'>
                <h2 className='text-center'>
                  SELF ASSESSMENT FORM{' '}
                  <span className='d-block'>
                    FOR <span>CRS</span> / <span>EE</span> ALSO <span>PNP</span>
                  </span>
                </h2>
                <p>
                  Check your eligibility for the following programs: Express
                  Entry, Study permit PNPs, Startup Visa, Work permit
                </p>
                <div className='align-items-center d-flex'>
                  <a
                    href='https://wa.me/+966558877372'
                    className='self-assessment-button'
                  >
                    Self Assessment Form
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <h2>Best Immigration consultants in Saudi Arabia</h2>
          <p>
            If you are planning to migrate to Canada, Australia and Europe or
            looking to study abroad? you need to contact an immigration
            consultant. Wayfair Immigration consultants can help make the
            process of relocating much easier and smoother, as we know all the
            rules and regulations that are involved in the process. We are one
            of the best immigration consultants in Saudi Arabia with 15 years of
            service, top-rated immigration consultants specializing in family
            immigration to Canada from Riyadh, Saudi Arabia.
          </p>
          <p>
            If you are looking for a reliable immigration consultant in Saudi
            Arabia, then you have come to the right place. We are a team of
            experienced and qualified consultants who can help you with all your
            immigration needs. Whether you are looking for a visa to enter
            Canada/Australia or want to apply for permanent residency, we can
            assist you through the entire process. We have a proven track record
            of successful applications and will work diligently to ensure that
            your application is successful. Contact us today to schedule a
            consultation.
          </p>
          <h2>Apply PR visa in Canada/Australia from Saudi Arabia</h2>
          <p>
            There are many people who want to apply for a PR visa in Canada or
            Australia from Saudi Arabia. But the process is not so easy. You
            need to have a lot of documents and you need to meet the eligibility
            criteria. But if you take the help of an immigration consultant,
            then the whole process will be very smooth and easy for you. We, the
            immigration consultants will help you in preparing all the documents
            required for the visa application. We will also guide you through
            the whole process and will make sure that your application is
            successful. So, if you are planning to apply for a PR visa in Canada
            or Australia from Saudi Arabia, then we are here to help you.
          </p>
          <h2>Canada immigration service</h2>
          <p>
            The first step in the Canadian immigration process is to determine
            your eligibility. There are many factors that will be considered,
            such as your age, education, work experience, and language skills.
            Consultants can help you assess your eligibility and provide you
            with the necessary information to move forward with your
            application. Once you have determined that you are eligible to apply
            for Canadian immigration, consultants can help you with the next
            steps in the process. This includes helping you gather the required
            documentation, preparing your application, and submitting it to the
            appropriate authorities. Consultants can also provide support
            throughout the entire process, from start to finish. If you are
            interested in immigrating to Canada, it is important to consult with
            an experienced immigration consultant. They will be able to guide
            you through the process and ensure that you have a successful
            application.
          </p>
          <h2>Australia immigration service</h2>
          <p>
            There are many reasons why you may choose to migrate to Australia.
            Perhaps you have family there, or you may be looking for better job
            prospects. Whatever the reason, if you're considering immigrating to
            Australia, it's important to seek out professional help.
          </p>
          <p>
            The immigration process can be complex and confusing, so it's vital
            that you have a knowledgeable and experienced consultant on your
            side. Wayfair consultants is the best immigration consultants in
            Riyadh, Saudi Arabia. Here are just a few of the reasons why:
          </p>
          <ul>
            <li>We Have a Deep Understanding of Australian Immigration Law</li>
            <li>We can Help You Choose the Right Visa Category</li>
            <li>
              We can Help You Avoid Common Pitfalls in the Application Process
            </li>
          </ul>
          <p>
            From ensuring that your application is complete and accurate, to
            providing detailed guidance on what supporting documentation you
            need, an experienced consultant can help you avoid common pitfalls
            in the application process. This can save you time, money, and
            stress in the long run.
          </p>
          <h2>Poland immigration</h2>
          <p>
            Poland has been a member of the European Union since 2004 and offers
            many opportunities for work and study. The country has a strong
            economy and its population is well-educated. There are also many
            cultural attractions in Poland, making it an attractive destination
            for immigrants.
          </p>
          <p>
            If you are interested in immigrating to Poland, it is important to
            choose an immigration consultant who can help you with the process.
            At Wayfair immigration consultants, we have a team of experienced
            consultants who can assist you with every step of your immigration
            journey. Contact us today to learn more about our services and how
            we can help you immigrate to Poland.
          </p>
          <h2>Study visa in Europe</h2>
          <p>
            There are a number of reasons why studying in Europe is such a
            popular choice for students from Saudi Arabia. Not only are there
            many world-renowned universities to choose from, but the cost of
            living and studying in Europe is often much cheaper than in other
            parts of the world. Finding a right course in a foreign university
            can be challenging to students and their parents. If you're planning
            on studying in Europe, you will need to apply for a student visa.
            The process of applying for a student visa can be tricky, so it's
            important to seek out professional help from an immigration
            consultant. We the best immigration consultants in Saudi Arabia will
            be able to help students to attain admission in desired universities
            and guide you through the entire process of applying for a study
            visa, from start to finish.
          </p>
        </div>
      </div>
      <div className='membership'>
        <div className='row'>
          <div className='col'>
            <h2 className='membership-heading'>membership</h2>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6 col-sm-12 maara'>
            <img src={maara} alt='migration agents registration authority' />
          </div>
          <div className='col-md-5 offset-md-1 col-sm-12 iccrc'>
            <img
              src={iccrc}
              alt='immigration consultants of canada regulatory council'
            />
          </div>
        </div>
      </div>

      <div className='testimonials'>
        <div className='row'>
          <div className='col-md-12'>
            <h2>Happy customers</h2>
            <p className='mb-4'>
              Our customers leave positive feedback around the world
            </p>
            <Reviews />
          </div>
        </div>
      </div>
    </>
  )
}
