import { BrowserRouter, Routes, Route } from 'react-router-dom'
import React from 'react'
import './App.css'
import { Home } from '../src/pages/Home'
import { Australia } from '../src/pages/Australia'
import { AboutUs } from '../src/pages/AboutUs'
import { StudentVisa } from '../src/pages/StudentVisa'
import { Contact } from '../src/pages/Contact'
import { Canada } from '../src/pages/Canada'
import { Navbar } from '../src/shared/Navbar'
import { Error } from '../src/pages/Error'
import Footer from '../src/shared/Footer'

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <div className='container'>
        <Routes>
          <Route path='/' element={<Home></Home>}></Route>
          <Route path='about-us' element={<AboutUs />}></Route>
          <Route path='student-visa' element={<StudentVisa />}></Route>
          <Route path='australia' element={<Australia />}></Route>
          <Route path='canada' element={<Canada />}></Route>
          <Route path='contact' element={<Contact />}></Route>
          <Route path='*' element={<Error />}></Route>
        </Routes>
      </div>
      <Footer></Footer>
    </BrowserRouter>
  )
}

export default App
